<template lang="">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-12 col-md-12 col-xl-12">
      <div class="productheader">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="w-50 d-flex gap-2 flex-wrap">
            <img
              class="productImage"
              :src=this.insCompanyLogo
            />
            <div class="d-flex align-items-center flex-wrap gap-2">
                <h2 class="productName mb-0">{{ this.productName}}</h2>
            <span class="productSpan">({{this.policyType}})</span>
            </div>
          </div>
          <div class="w-50 ">
           
            <h2 class="journeyId text-end">
              <span class="productSpan">Reference Number</span> : {{journey_id}}
            </h2>
            <h2 class="journeyId text-end mt-2">
                <span class="productSpan">Premium Amount</span>
               : &#8377; {{currency(totalPremiumWithTax)}}
            </h2>
            <div class="text-end d-flex align-items-center flex-wrap justify-content-center">
                <span @click="showBreakupmodal()" class="text-primary show-breakup-link"
                 style="font-size: 12px;text-decoration: underline; margin-left:448px">Premium Breakup</span>              </div>
          </div>
        </div>
        <div class="formobileview">
                <div class="single-quote headingcards d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="mb-0">
                      {{ motorProductType === '4W-Pvt' ? 'Car Details' : 'Bike Details' }}
                    </h6>
                    <p class="mb-0">
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.rtoCode }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.carMake?.makeDescription }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.model?.modelDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.fuelType?.fuelType.toUpperCase()}}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.variant?.variantDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        formatDate(this.userData.RegDate)}} </span>
                    </p>
                  </div>

                </div>
              </div>
            </div>
    </div>
  </div>
  <hr class="m-0" />
  <AmountBreakup v-if="this.openAmountBreakupflag" @emitClose="emitClose()" />
</template>
<script>
import AmountBreakup from "@/modals/amountBreakup.vue";
export default {
    name: "productheader",
  components: {
    AmountBreakup
  },
  mounted() {
    this.motorProductType = this.$store.state.motorProductType;
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.userData, "userData")
    }
    if(JSON.parse(sessionStorage.getItem("journey_id"))){
    this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    }
    this.getSelectedPlanData();
  },
  data() {
    return {
        userData:{},
        openAmountBreakupflag: false,
        journey_id: "",
        selectedPlan:"",
        productName:"",
        policyType:"",
        insCompanyLogo:"",
        totalPremiumWithTax:"",
        motorProductType:"",

    };
  },
  methods:{
    // Date fromatting to DD-MM-YYYY Fromat
    formatDate(date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    showBreakupmodal() {
      console.log("clicked-------");
      this.openAmountBreakupflag = true;
    },
    emitClose() {
      this.openAmountBreakupflag = false;
    },
    getSelectedPlanData() {
      //  An interval to check for the selected plan
      const timer = setInterval(() => {
        const planData = sessionStorage.getItem("selected_plan_data");
        if (planData) {
          this.selectedPlan = JSON.parse(planData);
          if (this.selectedPlan) {
            clearInterval(timer);
            this.productName = this.selectedPlan.insuranceAndProducts.productName;
            this.policyType = this.selectedPlan.insuranceAndProducts.policyType;
            this.insCompanyLogo = this.selectedPlan.supportingData.insCompanyLogo;
            this.totalPremiumWithTax = this.selectedPlan.productDetails.totalPremiumDetails[0].totalPremiumWithTax;
          }
        }
      }, 200);
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
};
</script>
<style scoped lang="scss">

@import '../../assets/scss/components/productDetailsHeader.scss';
@import "../../assets/scss/components/form-wizard.scss";
.show-breakup-link {
  font-size: 12px;
  // text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}
.dark .show-breakup-link{
  color: $main-bg !important;
}


</style>
