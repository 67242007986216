<template>
  <article
    class="FilterAccordian"
    :key="this.title"
  >
    <header class="d-flex justify-content-between align-items-center">
      <h6 class="question-title mb-0">
        No Claim Bonus ({{ this.title}})
      </h6>
    </header>
    <transition mode="out-in" name="fadeAndSlide">
      <div class="ncb_outer-container">
        <div class="d-flex align-items-center justify-content-between ncbPercent_container my-2 p-2">
          <p>Made a claim in your current Policy?</p>
          <div class="form-check form-switch my-0 position-relative p-0">
            <label class="form-check-label headinglabel mb-0" for="flexSwitchCheckChecked" >{{ toggleFlag ? 'Yes' : 'No' }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              v-model="toggleFlag"   
              @click="toggleFunction()"
            />
          </div>
        </div>
        <h4 class="my-3" v-if="!this.toggleFlag">{{ this.info }}</h4>
        <div>
          <!-- <div class="form-check form-switch my-3 position-relative p-0">
            <label class="form-check-label headinglabel" for="flexSwitchCheckChecked" >{{ toggleFlag ? 'Yes' : 'No' }}</label>
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              v-model="toggleFlag"   
              @click="toggleFunction()"
          
            />
          </div> -->
          <div
            :style="contentStyle"
            class="content"

          >
            <div :style="infoStyle" class="info d-flex align-items-center gap-1 flex-wrap">
              <div v-if="!this.toggleFlag" v-for="(item,index) in this.percentOptions"
              :key="index" class="form-check ncb-percent-container mt-2">
                <input
                  v-if="this.title==='Policy Type'"
                  class="form-check-input"
                  type="checkbox"
                  :id="'checkbox_' + item"
                  :value="item"
                />
                <input
                  v-else-if="this.title === 'NCB'"
                  class="form-check-input"
                  type="radio"
                  :id="'radio_' + item"
                  :value="item"
                  :name=" userData.percentOptions" 
                  v-model="selectedItem"
                />
                <label
                  :for="'radio_' + item"
                  class="form-check-label px-3 py-2"
                >
                  <div class="d-flex align-items-center gap-2">
                    <h5 class="mb-0">{{ item }}</h5>
                  </div>
                </label>
              </div>
              <small v-else class="error-text">NCB is not applicable</small>
            </div>

            <div class="mt-3 ncb_eligibility" v-if="selectedItem!='0%' && !toggleFlag">
              <p class="p-2">You are eligible for <b>{{selectedItem}} NCB</b> in your new policy</p>
              <p class="p-2">If the declared NCB% is wrong, insurer may reject your claim.</p>
            </div>
          </div>
          
          <div  class="d-flex gap-4 mt-3 justify-content-end">
            <button @click="cancel()" class="btn backbutton" style="width: fit-content">
              Cancel
            </button>
            <button class="btn ApplyButton" @click="apply()">Apply</button>
          </div>
        </div>
      </div>
  </transition>
    
  </article>
</template>

<script>
import { ref, computed} from "vue";

export default {
  data() {
    return {
      toggleFlag:null,
      previous_year_claim:null,
      selectedItem:null,
      odDate:'',
      userData:{},
      percentOptions:[],
    };
  },
  name: "FilterAccordian",
  props: {
    title:  {
      type: String,
      required: true,
    },
    info:  {
      type: String,
      required: true,
    },
  },
  mounted(){
//get User data
this.userData = JSON.parse(sessionStorage.getItem("userData"));
this.selectedItem = this.userData.applicablePercentage;
this.odDate=this.userData.OdDate;
let isExpInPrev90Days=this.isDateWithinLast90Days(this.odDate)
// if(isExpInPrev90Days){
//   this.percentOptions.push(this.userData.percentOptions[0]);
// }
// else{
  this.percentOptions=this.userData.percentOptions;
// }
this.previous_year_claim = this.userData.previous_year_claim;
this.toggleFlag = this.userData.previous_year_claim;
},
methods:{
  isDateWithinLast90Days(odDate) {
    const dateToCheck = new Date(odDate);
    if (isNaN(dateToCheck)) return false;
    const today = new Date();
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(today.getDate() - 90);
    return dateToCheck >= ninetyDaysAgo && dateToCheck <= today;
  },
  //Cancel Selected values
  cancel(){
    this.toggleFlag=null
    this.selectedItem = null;
    this.toggleFlag = this.userData.previous_year_claim;
    this.selectedItem = this.userData.applicablePercentage;
    this.$emit('handleIdvClose');
  },
  //Apply Ncb Change
  apply()
  {
    this.userData.applicablePercentage = this.selectedItem;
    this.userData.previous_year_claim = this.toggleFlag;
    sessionStorage.setItem("userData",JSON.stringify(this.userData))
    this.$emit('update:selected-item', this.selectedItem);
    this.$emit('clearCompareList') // Emit event to parent
    this.$emit('handleIdvClose');
  },

  //toggle changed
  toggleFunction(){
    this.toggleFlag = !this.toggleFlag;
    // this.userData.previous_year_claim =  this.toggleFlag 
    if(this.userData.previous_year_claim)
  {
    this.userData.applicablePercentage = 0;
  }
  if(this.toggleFlag==true){
    this.selectedItem = '0%';
  }else{
    this.selectedItem = this.userData.applicablePercentage;
  }

    
  },
//   onRadioChange(event){
// this.userData.applicablePercentage = this.selectedItem;

//   }
},

  setup() {
    const expanded = ref(true);
    const contentStyle = computed(() => {
      return { "max-height": expanded.value ? "200px" : "0px" };
    });

    const infoStyle = computed(() => ({
      display: expanded.value ? "block" : "none",
    }));

    return { expanded, contentStyle, infoStyle };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/components/filters.scss";
@import "../../assets/scss/components/select-card.scss";
.fadeAndSlide-enter-from,
.fadeAndSlide-leave-to{
  opacity: 0;
}
.fadeAndSlide-enter-active,
.fadeAndSlide-leave-active{
  transition: opacity 0.3s ease-in-out;
}
.ncb_outer-container{
  .ncbPercent_container{
    border-radius: 6px;
    background-color: $base-dark-text;
    p{
      font-size: 14px;
      color: $base-text;
      opacity: 1;
    }
    .form-switch{
      gap: 0;
      input{
        transform: scale(0.8);
        cursor: pointer;
      }
    }
  }
  h4{
    font-size: 14px !important;
    color: #1a1b1e !important;
    opacity: 0.7 !important;
    font-weight: 600;
  }
  .info{
    .form-check{
      padding-left: 0 !important;
    }
    .form-check-input{
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
    }
    .form-check-label{
      border-radius: 20px;
      border: 1px solid $border-color;
      cursor: pointer;
    }
    .form-check-input:checked+.form-check-label{
      border-color: $main-bg;
      background-color: $base-dark-text;
      div h5{
        font-weight: 800;
        color: $main-bg;
      }
    }
  }
  .ncb_eligibility{
    border-radius: 8px;
    overflow: hidden;
    border: 2px solid $text-dark-color;
    p{
      opacity: 1;
      font-size: 14px !important;
    }
    p:first-child{
      background-color: $text-dark-color !important;
      opacity: 1;
      color: $success;
    }
  }
  .error-text{
    opacity: 1;
    font-size: 16px;
    color: $error-txt !important;
  }
}
</style>
